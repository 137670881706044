
body {
    overflow-x: hidden;
}


.box {
    display: flex;
    width: calc(100vw - 255px);
    background-color: #E5E5E5;
    /* overflow-y:hidden; */
    overflow-x: hidden;
    position: absolute;
}

.vbox {
    margin-left: 255px;
    height: 100vh;
    overflow-x: hidden;
    width: calc(100vw - 255px);
}

.modalBoxLeft {
    /* margin-right: 200px; */
}
.modalSidebarRight {
    width: 300px;
    height:max-content;
    background: rgba(225, 229, 235, 0.5);
    border-radius: 0px 0px 5px 0px;
    background: #FFFFFF;
}


.sidebar { 
    
    min-width: 255px;
    max-width: 255px; 
    position: absolute;
    height: 100vh;
    overflow-y:hidden;
    overflow-x: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background: #FFFFFF;
    border: 1px solid #E4E7EB;
    padding: 0px;
}

/* admins */
.sidebarAdmin { 
    width: 270px;
    position: relative;
    height: 100vh;
    overflow-y:hidden;
    overflow-x: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background: #FFFFFF;
    border: 1px solid #E4E7EB;
}

.adminOrgLabel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.0571429px;
    color: #1D3759;
}

$navbar-vertical {
    max-width:260px
}

.vnavMobile {
    display: block;
    width: 100%;
    overflow-y: auto;
    padding: 4px
}

.sidebarnav {
    min-width: 255px;
    max-width: 255px; 
    min-height: none;
    overflow-y:hidden;
    overflow-x: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


.sidebar2 { 
    position:fixed;
    right: 0;
    flex: 1 1 auto;
    overflow-y:auto;
    overflow-x: hidden;
    height:100%;
}


.sidebarBrand { 
    padding-top: 18px;
    padding-left: 11px;    
}
.sidebarBrandImg { 
    width: 230px;
    height: auto;
}

.iconPadding {
    padding-right: 2px;
}

.sidebarLinkText {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.0571429px;
    color: rgba(29, 55, 89, 0.5);
    width: 250px;
}

.sidebarWidth {
    width: 260px;
}


.sidebar-h-Line {
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin: 4px;
    width: 235px;
}

.userVNavColor {
    background: #FFFFFF;
    border: 1px solid #E4E7EB;
}

.userHnavColor {
    background: #FFFFFF;
    border: 0px;
    color: #0F8C8C;
}


.userHNav { 
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(55, 55, 55, 0.5);
    padding: none;
    height: 64px;
}

.userNameDisplay {
    padding-right: 5px;
}


.userNavSearchbox {
    min-width: 150px;
    max-width: 375px;
    border: 1px solid #A6A6A6;
}


.maincontent {
    margin-left: 1rem;
    height: calc(100vh - 72px);
    overflow-y:auto;
    overflow-x: hidden;
    flex: 1 1 auto;
}

.maincontentAdmins {
    margin-left: 1rem;
    height: calc(100vh - 101px);
    overflow-y:auto;
    overflow-x: hidden;
    flex: 1 1 auto;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.adminReportOrgSelectFilters {
    min-width: 100px;
    max-width: 300px;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.logoPadding {
    padding-right: 5px;
}

.navbarnav {
    height: 72px;
}

.verticalLine {
    border: 0;
    border-left: 1px solid rgba(0,0,0,0.1);
}

.h-Line {
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.adminNavMargin {
    border: 0;
    border-left: 1px solid rgba(134, 134, 134, 0.1);
}
.adminUserWidth {
    width: 75px;
}

.queryRowMargins {
    margin-bottom: 6px;
}

.tablemargins {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.queryExecutionsList {
    z-index: 1000;
    position:sticky;
    right: 0;
    height: calc(100vh - 72px);
    width: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* admins */
.queryExecutionsListAdmin {
    z-index: 1000;
    position:sticky;
    right: 0;
    height: calc(100vh - 101px);
    max-height: fit-content;
    width: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.queryExecutionsListItem {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.colorAdminModeWarning {
    color: red;
}

.nav-flush .nav-link {
    border-radius: 0;
  }

.scrollarea {
overflow-y: auto;
}

.navheight {
    height: 29px;
}

.PQDataDetails {
    max-width: 200px;
    /* padding: 3px; */
}

.PQDataMostRecent {
    max-width: 200px;
}

.PQRegionBadges {
    min-width: fit-content;
}

.copyQueryToUsersBtn {
    width: 150px;
}

.queryInfoBox {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 300px;
    height: 200px;
}

.queryInfoTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.0571429px;
    max-width: 285px;
}

.queryDownloadButton {
    background: #0F8C8C;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.0571429px;
    color: #FFFFFF;
}



.queryInfoButton {
    background: #FFFFFF;
    border: 1px solid #0F8C8C;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.0571429px;
    color: #0F8C8C;
}

.queryRunDownloadButton {
    background: #0F8C8C;
    border: 1px solid #0F8C8C;
    color: #FFFFFF;
    border-radius: 5px;
    text-align: center;
    height: 80px;
}

.queryRunInfoButton {
    background: #FFFFFF;
    border: 1px solid #0F8C8C;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.0571429px;
    color: #0F8C8C;
    height: 80px;
}

.queryRunButtonGroupArea {
    padding-left: 10px;
}

.queryRunListItem {
    height: 103px;
}



.queryReportCardSecondaryInfo {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.0571429px;
    color: #87919E;
}

.queryDetailsTextFormat { 
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.0571429px;
    color: #697585;
    background: #FFFFFF;

}

.queryRunListTextFormat { 
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.0571429px;
    color: #697585;
}





.QueryDetailsModalTextArea {
    max-width: 350px;
}


/* .moreInfoModal {
    max-height: 93vh;
} */

.moreInfoModalBody {
    height: calc(100vh - 245px );
    overflow-y: auto;
    overflow-x: hidden;
}

.QueryHistoryTitleText {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.0571429px;
    color: #87919E;
}

.ReportArea {
    padding-top: 15px;
}

.queryEditBtn {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.0571429px;
    color: #87919E;
    background-color: #FFFFFF;
}

.contractUsersEditButton {
    background: #0F8C8C;
    border: 1px solid #0F8C8C;
    color: #FFFFFF;
    border-radius: 5px;
    text-align: center;
}